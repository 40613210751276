.mat-accordion-menu-principal {
	.mat-expansion-panel-body {
		padding: 0;
	}
}

.mat-progress-bar {
	&.top-mat-card {
		margin: -16px -16px 16px -16px !important;
		width: auto;
		display: block;
	}
}
.mat-tab-form {
	.mat-tab-body-content {
		overflow-x: hidden;
	}
}

.mat-icon-table {
	margin: 0 !important;
}
.grecaptcha-badge {
	visibility: hidden;
	pointer-events: none;
}
