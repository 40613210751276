.section {
	width: 100%;
	margin: 0 auto;
	padding: 1rem;
	//min-height: calc(100% - 64px);
	//min-height: 100%;
	@media (max-width: 599px) {
		//min-height: calc(100% - 56px);
		///min-height: 100%;
	}

	&--xxl {
		max-width: calc(1600px);
	}

	&--xl {
		max-width: 1400px;
	}

	&--lg {
		max-width: 1200px;
	}

	&--md {
		max-width: 1000px;
	}

	&--sm {
		max-width: 800px;
	}

	&--xs {
		max-width: 600px;
	}
}
