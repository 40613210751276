.overflow-xv {
	overflow-x: auto;
}
.overflow-x {
	overflow-x: auto;
}
.overflow-y {
	overflow-y: auto;
}
.overflow-h {
	overflow: hidden !important;
}
.weight-n {
	font-weight: 400 !important;
}
.weight-b {
	font-weight: 500 !important;
}
.w-100 {
	width: 100%;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.static {
	position: static;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-justify {
	text-align: justify;
}
.text-primary {
	color: #089df6;
}
.text-danger {
	color: #f44336;
}
.uppercase {
	text-transform: uppercase;
}
.spacer {
	flex: 1 1 auto;
}
